import {Component, OnInit, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StatusService} from '../../services/status.service';
import {BsModalComponent} from 'ng2-bs3-modal/ng2-bs3-modal';
import {DbService} from '../../services/db.service';
import {DocService} from '../../services/doc.service';
import {AppSettings} from '../../app-settings';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DataIdoneita} from '../../data/data-idoneita';
import {DataRaccomandate} from '../../data/data-raccomandate';
import {DataDettagliExtraRicorso} from '../../data/data-dettagli-extra-ricorso';
import {DataSportello} from '../../data/data-sportello';
import {DataRicorso} from '../../data/data-ricorso';
import {FlashMessagesService} from 'angular2-flash-messages';
import {DomSanitizer} from '@angular/platform-browser';
import {Anagrafica} from '../../models/anagrafica';
import {Beneficiario} from '../../models/beneficiario';
import {Resp} from '../../models/resp';
import {Ricorrente} from '../../models/ricorrente';
import {DataEsportaAnagrafiche} from '../../data/data-esporta-anagrafiche';
import {ConvertInterfaceToDict, CsvType} from '../../interfaces/csv-type';
import {DataRichiestaCopie} from '../../data/data-richiesta-copie';
import {formatDate} from '@angular/common';
import {debounceTime, map, switchMap} from 'rxjs/operators';
import {DeleteRequestType} from '../../models/ricorso';
import {AnagraficaComponent} from '../anagrafica/anagrafica.component';
import {DomicilioDigitale} from '../../models/domicilio-digitale';
import {InputDomicilioDigitaleComponent} from '../input-domicilio-digitale/input-domicilio-digitale.component';
import {fromEvent, Subscription, timer} from 'rxjs';
import {PdfViewerComponent} from '../pdf-viewer/pdf-viewer.component';


@Component({
  selector: 'app-extra-ricorso',
  templateUrl: './extra-ricorso.component.html',
  styleUrls: ['./extra-ricorso.component.css'],
  providers: [DbService,DocService]
})
export class ExtraRicorsoComponent implements OnInit, AfterViewInit {


  //dato membro utilizzato per ma modale raccomandate, in cui mi basta scegliere il ricorrente mittente, conoscere il suo indice
  public racc:DataRaccomandate;

  public esportaAnagraficheSelection:DataEsportaAnagrafiche;

  public refCsvObject : ConvertInterfaceToDict<CsvType>;

  public ido:DataIdoneita;
  public domicilioDigitaleData:DataIdoneita;
  public domicilioDigitale:DomicilioDigitale;
  public _sportello:DataSportello;
  public _ricorso:DataRicorso;
  private _idRicorso:number;
  private _beneficiario:Beneficiario;
  public real_ext:string;

  public dataDocumento:string;


  private _anagrafica:Anagrafica;

  // richiesta copie e nota iscrizione a ruolo
  public stateCopyDetails: string;

  public copie: DataRichiestaCopie;
  public delegati: any[] = [];

  private _localRicorrenti: Ricorrente[];
  private _localBeneficiario: Beneficiario;  // Luke 02/03/2021
  private dataRicorso: DataRicorso = new DataRicorso();
  private selectedKind: string;

  private _dettagli:DataDettagliExtraRicorso;
  public listUploadedDocs;
  public pages:number[];
  public loadingListUploadedDocs:boolean[];
  public media:any;
  public showLoadingValue:boolean;
  private listaPropostaNominativi:any = [];

  private idProposta: number;

  private accettaPrivacy:boolean; // Luke 12/03/2021 : accettazione privacy con ulteriore richiesta di conferma

  private tipoCancellazione : DeleteRequestType;
  private IdoForRicorrenti:boolean;

  private DomicilioDigitaleForRicorrenti:boolean;


  //** nrg modale **//
   private showNrgSuccess:boolean;
   private nrgSuccess:string;

   private animation=true;
   private keyboard=true;
   private backdrop=false;
   private cssClass='';

    private linkRichiestaCopie: boolean;
    private linkNotaIscrizione: boolean;
    public pathRichiestaCopie: string;
    public pathNotaIscrizione: string;

    private linkEtichette:boolean;
    private pathEtichette:string;

    private linkIdo:boolean;
    private linkDomicilioDigitale:boolean;
    private pathIdo:string;
    private pathDomicilioDigitale:string;

    private linkRaccomandate:boolean;
    private pathRaccomandate:string;

    private linkProposta:boolean;
    private pathProposta:string;
    private availableProposta:boolean;
    private availableRichiestaCopie:boolean;
    private availableNotaRuolo:boolean;
    private availableEsportaAnagrafiche:boolean;
    private availableDomicilio:boolean;
    private availablePrivacy:boolean;
    private availableArchivio:boolean;
    private availableIdoneita:boolean;
    private availableEtichette:boolean;
    private availableRaccomandate:boolean;



    private showErrorPattern:boolean;


    private pathZip:string;
    private linkZip:boolean;
    private linkZipStatus:string;

    private pathPrivacy:string;
    private linkPrivacy:boolean;

    private selectedFile : File[];

    //** inizio modale per il caricamento del documento selezionato **//

    private type:string;
    private content:string;
    private url;
    private path;
    private extension;

    //** fine nrg modale *

    // app-pdf-viewer
    @ViewChild('pdfViewerRef',{static:false}) pdfViewer!: PdfViewerComponent;

    currentFileName = '';
    isLoading = false;

    @ViewChild('notaRuoloModal',{static:false})
    notaRuoloModal: BsModalComponent;

    @ViewChild('richiestaCopieModal',{static:false})
    richiestaCopieModal: BsModalComponent;

    @ViewChild('raccomandateModal',{static:false})
    raccomandateModal: BsModalComponent;

    @ViewChild('esportaAnagraficheModal',{static:false})
    esportaAnagraficheModal: BsModalComponent;


      @ViewChild('zipModal',{static:false})
    zipModal: BsModalComponent;

    @ViewChild('privacyModal',{static:false})
    privacyModal: BsModalComponent;

    @ViewChild('etichetteModal',{static:false})
    etichetteModal: BsModalComponent;

    @ViewChild('idoModal',{static:false})
    idoModal:BsModalComponent;


    @ViewChild('domicilioDigitaleModal',{static:false})
    domicilioDigitaleModal:BsModalComponent;


    @ViewChild('nrgModal',{static:false})
    nrgModal:BsModalComponent;

    @ViewChild('nrgModalForm',{static:false}) nrgModalForm;

    @ViewChild('uploadDocumentModal',{static:false})
    uploadDocumentModal:BsModalComponent;

    @ViewChild('uploadDocumentModalForm',{static:false}) uploadDocumentModalForm;
    @ViewChild('propostaModalForm', {static:false}) propostaModalForm;


    @ViewChild('showDocumentModal',{static:false}) showDocumentModal:BsModalComponent;

       @ViewChild('selectedDocumentModal',{static:false}) selectedDocumentModal:BsModalComponent;


    @ViewChild('propostaModal',{static:false}) propostaModal:BsModalComponent;

    @ViewChild('deleteModal',{static:false}) deleteModal:BsModalComponent;

    @ViewChild('nominativo',{static:false}) nominativo:AnagraficaComponent;

    @ViewChild('domicilio',{static:false}) refDomicilioDigitaleComponent:InputDomicilioDigitaleComponent;



  private idToDelete:number;
    private deleteShowError:boolean;
    private nome_selezionato:string;
     private deleteErrorText:string;
     private successMessage:boolean;


     private getUploadCompleted:boolean;

    setWindowDetails(sportello: DataSportello) {
        console.log('setWindowDetails');
        this._sportello = sportello;
        console.log(this._sportello);
    }

  setListUploadedDocs(data){
      if(data[0]==null)
        this.listUploadedDocs=null;
    else{
      this.listUploadedDocs=data;
      this.loadingListUploadedDocs=[...Array(data.mediaSize).fill(false)];
      }
    }


   closedDeleteModal() {
        this.deleteShowError=false;
         this.successMessage=false;
    }

    dismissedDeleteModal() {
        this.deleteShowError=false;
         this.successMessage=false;
    }

    openedDeleteModal() {
      this.deleteShowError=false;
       this.successMessage=false;

    }


    openDeleteModal() {
        this.deleteModal.open();
    }



    setModalDelete(nome:string,idCaricamento:number){
      this.tipoCancellazione=DeleteRequestType.CARICAMENTO;
      this.idToDelete=idCaricamento;
      this.nome_selezionato=nome;
      this.deleteModal.open();
    }

    setModalDeleteForPropostaNominativo(proposta){
        this.tipoCancellazione=DeleteRequestType.PROPOSTA_NOMINATIVO;
        this.idToDelete=proposta.idProposta;
        this.nome_selezionato=proposta.propostaAds.cognome;
        this.deleteModal.open();
        //this.nominativo.form.reset();
    }


    deleteConfirmModal(){
        if(this.tipoCancellazione == DeleteRequestType.CARICAMENTO){
            this.dbService.deleteCaricamentoFromId(this.idToDelete)
                .subscribe(data =>this.processDeleteCaricamento(data));
        } else if(this.tipoCancellazione == DeleteRequestType.PROPOSTA_NOMINATIVO){
            this.cancellaAnagraficaPropostaNominativo();
        }
        this.deleteModal.dismiss();
    }


    processDeleteCaricamento(data){
      let time=0;
      if(data.error!=''){
        this.deleteShowError=true;
        this.deleteErrorText=data.error;
        time=4400;

      }else{
        this.deleteShowError=false;
        this.successMessage=true;
       time=2200;
      }


       setTimeout( () => {  this.deleteModal.close(); }, time );

      this.dbService.getFileForRicorso(this._idRicorso)
      //.map(res => res.json())
      .subscribe(_data =>
         this.setListUploadedDocs(_data)


        );



    }




   constructor(private docService:DocService,private sanitizer:DomSanitizer, private http:HttpClient,private _flashMessagesService: FlashMessagesService, private dbService: DbService, private route:ActivatedRoute, private router:Router,private statusService:StatusService) {
    /* interval(1200).subscribe(()=>{
      console.log(`show loading vale ${this.showLoadingValue}`);
    })*/
    }

    checkAccess(){
      if(!this.statusService.getAccess()){
          this.router.navigate(['']);
      }


   }


    ngOnInit() {
        this.showLoadingValue=false;
        this.checkAccess();
        this._idRicorso=this.route.snapshot.params['idRicorso'];
        this.ido= new DataIdoneita();
        this.domicilioDigitaleData = new DataIdoneita(); // modello per la scelta del ricorrente
        this.domicilioDigitale = new DomicilioDigitale(); // modello per la componente input-domicilio-digitale
        this.racc= new DataRaccomandate();
        this._ricorso= new DataRicorso();
        this._dettagli=new DataDettagliExtraRicorso();
        this._anagrafica= new Anagrafica();
        this._beneficiario = new Beneficiario();
        this.listUploadedDocs=null;
        this.accettaPrivacy = false;  // Luke 12/03/2021 : accettazione privacy con ulteriore richiesta di conferma
        this.esportaAnagraficheSelection = new DataEsportaAnagrafiche();
        this.refCsvObject = {};
        this.copie = new DataRichiestaCopie(); // di default copie.idx vale 0, questo seleziona il primo ricorrente come candidato ads
        console.log(this.ido);

  }

  ngAfterViewInit(){
        this.availableProposta = true;
        this.availableRichiestaCopie = true;
        this.availableNotaRuolo= true;
        this.availableEsportaAnagrafiche= true;
        this.availableDomicilio= true;
        this.availablePrivacy=true;
        this.availableArchivio=true;
        this.availableIdoneita=true;
        this.availableEtichette=true;
        this.availableRaccomandate=true;
        this.syncronizeNrg();



  }


    getIdTribunale(){
        return this.statusService.getIdTribunale();
    }



    closedNotaRuolo(){
        this.linkNotaIscrizione = false;
        this.availableNotaRuolo= false;
        this.deletePdf(this.pathNotaIscrizione).subscribe(() => {
            this.notaRuoloModal.dismiss();
            this.availableNotaRuolo = true;
        });
    }
    closedRichiestaCopie(){
        console.log(`closedRichiestaCopie event`);
        this.linkRichiestaCopie = false;
        this.availableRichiestaCopie = false;
        this.deletePdf(this.pathRichiestaCopie).subscribe(() => {
            this.richiestaCopieModal.dismiss();
            this.availableRichiestaCopie = true;
            console.log(`ora this.availableRichiestaCopie vale ${this.availableRichiestaCopie}`);
        });
    }
    closedRaccomandate(){}
    closedIdo(){}
    closedDomicilioDigitale(){}
    closedEsportaAnagraficheModal(){}

    closedNrg(){
        this.syncronizeNrg();
    }
    dismissedNrg(){
        this.syncronizeNrg();
    }
    openedNrg(){
        this.showNrgSuccess=false;
    }

    syncronizeNrg(){
        this.dbService.getNrg(this._idRicorso)
            .subscribe(data => this.setNrg((data as Resp).data));
    }

    openNrg(){
       this.syncronizeNrg();
       timer(1000).subscribe(()=>{
           this.nrgModal.open();
       });

    }

setNrg(nrg){
  this._ricorso.nrg=nrg;

}


    // notaRuolo
    openedNotaRuolo(){}
    dismissedNotaRuolo(){
        this.availableNotaRuolo = false;
        this.linkNotaIscrizione = false;
        this.deletePdf(this.pathNotaIscrizione).subscribe(() => {
            this.notaRuoloModal.dismiss();
            this.availableNotaRuolo = true;
        });
    }

    //richiestaCopie
    openedRichiestaCopie(){}
    dismissedRichiestaCopie(){
        console.log('dismissedRichiestaCopie event');
        this.availableRichiestaCopie = false;
        this.linkRichiestaCopie = false;
        this.deletePdf(this.pathRichiestaCopie).subscribe(() => {
            this.richiestaCopieModal.dismiss();
            this.availableRichiestaCopie = true;
            console.log(`ora this.availableRichiestaCopie vale ${this.availableRichiestaCopie}`);

        });
    }


// archivio zip modale

 closedZip(){}

  dismissedZip(){
    console.log('dismissedZip');
     this.linkZip=false;
     this.availableArchivio = false;
     this.deletePdf(this.pathZip).subscribe(()=>{
          this.zipModal.dismiss();
          this.availableArchivio = true;
    });
  }
  openedZip(){

  }

  openZip(){
    this.linkZip=false;
    this.linkZipStatus='neutral';
    this.zipModal.open();
    this.dbService.getZip(this._idRicorso)
    //.map(res => res.json())
    .subscribe(data  => {
      this.showZip(data);
    });
    //this.nrgModal.open();
  }
// privacy modale


 closedPrivacy(){}
  dismissedPrivacy(){
    console.error('privacyModal.dismiss() called!');
    this.privacyModal.dismiss();
    this.linkPrivacy=false;
    this.availablePrivacy=false;
    this.deletePdf(this.pathPrivacy).subscribe(()=>{
        this.availablePrivacy = true;

    });
  }
  openedPrivacy(){


  }

  openPrivacy(){
        this.linkPrivacy=false;
    console.log(`openPrivacy idSportello vale ${this.statusService.getIdSportello()}`);
     this.dbService.getWindow(this.statusService.getIdSportello())
    .subscribe(data => this.configureSportelloForPrivacy((data as Resp).data));



  }

  configureSportelloForPrivacy(sportello){
    console.log('configureSportelloForPrivacy ha ricevuto');
    console.log(sportello);
    this._sportello=sportello[0];
    this._sportello.idSportello=+this._sportello.idSportello;
      this.privacyModal.open();
    this.docService.getPrivacy(this._sportello)
    .subscribe(data => {
      this.showPrivacy(data);
     });

    console.log(this._sportello.idSportello);
  }


  confermaPresaVisione(){
    this.dbService.confermaPresaVisioneRicorso(this._idRicorso).subscribe(data=>{
      alert((data as Resp).msg);
    });
  }



deletePdf(name){
   return this.dbService.deletePdf(name);

}


//fine archivio zip modale



  closedEtichette(){}

  dismissedRaccomandate(){
    this.linkRaccomandate=false;
    this.availableRaccomandate = false;
    this.deletePdf(this.pathRaccomandate).subscribe(()=>{
          this.raccomandateModal.dismiss();
          this.availableRaccomandate=true;
    });
  }

    dismissedEsportaAnagraficheModal(){
        this.esportaAnagraficheModal.dismiss();
    }

  dismissedEtichette(){
    this.linkEtichette=false;
    this.availableEtichette = false;
    this.deletePdf(this.pathEtichette).subscribe(()=>{
          this.etichetteModal.dismiss();
          this.availableEtichette= true;
          //this.cleanPdf('boxEtichette');
    });
  }


    dismissedDomicilioDigitale(){
        this.linkDomicilioDigitale=false;
        this.availableDomicilio= false;
        this.deletePdf(this.pathDomicilioDigitale).subscribe(()=>{
            this.domicilioDigitaleModal.dismiss();
            this.availableDomicilio= true;
        });
    }

  dismissedIdo(){
    this.linkIdo=false;
    this.availableIdoneita=false;
    this.deletePdf(this.pathIdo).subscribe(()=>{
          this.idoModal.dismiss();
          this.availableIdoneita=true;
    });
  }

    opendedIdo(){}

    openedEtichette(){}

    openedRacomandate(){}

    openedEsportaAnagraficheModal(){}

    openRaccomandate(){
        console.log("...ho aperto la modale raccomandate");
       this.dbService.getRicorsoFromDb(this._idRicorso,this.getIdTribunale()).subscribe(data => this.configRicorrentiForRaccomandate((data as Resp).data,this._idRicorso));
    }
    openEsportaAnagrafiche(){
        this.esportaAnagraficheModal.open();
    }
    openNotaRuolo(){
        this.copie.date = formatDate(new Date().toDateString(), 'dd/MM/yyyy', 'it-IT');
        console.error(`openNotaRuolo, idRicorso vale ${this._idRicorso}`);
        this.loadDocForNotaRuolo(this._idRicorso);
    }

    loadDocForNotaRuolo(idDocumento:number){
        this.dbService.getRicorsoFromDb(idDocumento,this.getIdTribunale())
            .subscribe(data => this.configLoadedDocForNotaRuolo((data as Resp).data,idDocumento));
    }
    loadDocForRichiestaCopie(idDocumento:number){
        this.dbService.getRicorsoFromDb(idDocumento,this.getIdTribunale())
            .subscribe(data => this.configLoadedDocForRichiestaCopie((data as Resp).data,idDocumento));
    }

    configLoadedDocForNotaRuolo(data:any,idDocumento:number){
        console.log('configLoadedDocForNotaRuolo data vale');
        console.log(data);
        this._localRicorrenti = data.ricorrenti;
        this._localBeneficiario = data.beneficiario;
        this.dataRicorso.ricorrenti = data.ricorrenti;
        //this.printNota_Copie('note', '', data.ricorrenti, data.beneficiario);

        this.notaRuoloModal.open();

    }

    configLoadedDocForRichiestaCopie(data:any,idDocumento:number){

        this._localRicorrenti = data.ricorrenti;
        this._localBeneficiario = data.beneficiario;
        this.dataRicorso.ricorrenti = data.ricorrenti;
        console.log(this._localRicorrenti);
        console.log('_localBeneficiario: ', this._localBeneficiario);
        this.richiestaCopieModal.open();

    }

    apriPdfCopie() {
        window.open(this.pathRichiestaCopie, '_blnk');
    }

    printNota_Copie(kind, idTipoDocumento: string, ricorrenti, beneficiario) {

        // 1

        console.log('idx vale', this.copie.idx);


        const ads = ricorrenti[this.copie.idx];
        console.log('ricorrente è ', ads);
        const bene = beneficiario;
        console.log('beneficiario è ', bene);

        let _details = {};
        if (kind == 'note') {
            _details = this.statusService.getRicorso().details;
        }
        let data;

        if (kind == 'copy') {

            // Prepara i dati del delegato se presente
            let delegatoInfo = null;

            console.log(this.copie);
            let foundDelegato = false;

            if (this.copie.delegatoId) {
                // Se è stato selezionato un delegato esistente
                const delegato = this.delegati.find(d => d.idDelegato === this.copie.delegatoId);
                if (delegato) {
                    foundDelegato = true;
                    delegatoInfo = {
                        nome: delegato.nome,
                        cognome: delegato.cognome
                    };
                }
            } else if (this.copie.delegatoNome !='' && this.copie.delegatoCognome != '') {
                // Se è stato inserito un nuovo delegato
                foundDelegato = true;
                delegatoInfo = {
                    nome: this.copie.delegatoNome,
                    cognome: this.copie.delegatoCognome
                };
            }
            if(!foundDelegato){
                delegatoInfo = null;
            }


            data = {ads: JSON.stringify(ads), copie: JSON.stringify(this.copie), bene: JSON.stringify(bene),
                delegato: delegatoInfo ? JSON.stringify(delegatoInfo) : null,
                idTipoDocumento, id: this.statusService.getSpecialId()};
        } else {
            data = {details: JSON.stringify(_details), ads: JSON.stringify(ads), copie: JSON.stringify(this.copie), bene: JSON.stringify(bene), idTipoDocumento, id: this.statusService.getSpecialId()};
        }

        const json = JSON.stringify(data);
        console.log('sono la print');

        console.log(json);


        let headers;
        switch (kind) {
            case 'copy':
                console.log(json);
                headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
                this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/copyRicorso.php`, json, {headers})
                    //  .map(res => res.json())
                    .subscribe(_data => this.showDocumentCopy(_data));

                break;
            case 'note': {
                console.log(json);
                headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
                this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/noteRicorso.php`, json, {headers})
                    // .map(res => res.json())
                    .subscribe(_data => this.showDocumentNote(_data));

                break;
            }

        }
    }

    showDocumentCopy(data) {
        this.pathRichiestaCopie = data.fileName;
        this.linkRichiestaCopie = data.linkRichiestaCopie;
        this.dbService.addZip(this._idRicorso, this.pathRichiestaCopie).subscribe(() => {});
        console.log('richiesta copie creata');
    }
    getCopyCompleted() {
        return this.linkRichiestaCopie;
    }

    getNoteCompleted() {
        return this.linkNotaIscrizione;
    }

    showDocumentNote(data) {
        this.pathNotaIscrizione = data.fileName;
        this.linkNotaIscrizione = true;
        this.dbService.addZip(this._idRicorso, this.pathNotaIscrizione).subscribe(() => {});
    }

    openRichiestaCopie(){
        this.stateCopyDetails = 'inactive';
        this.linkRichiestaCopie = false;

        this.copie.date = formatDate(new Date().toDateString(), 'dd/MM/yyyy', 'it-IT');

        this.dbService.getWindow(this.statusService.getIdSportello())
            .subscribe(data => this.configureSportelloForRichiestaCopie((data as Resp).data));

    }

    configureSportelloForRichiestaCopie(sportello:any){
        console.log('configureSportelloForPrivacy ha ricevuto');
        console.log(sportello);
        this._sportello=sportello[0];
        this._sportello.idSportello=+this._sportello.idSportello;
        this.copie.comuneSportello = this._sportello.comune;
        if (this._sportello) {
            this.dbService.getDelegatiList(this._sportello.idSportello)
                .subscribe((response: any) => {
                    if (response && response.data) {
                        this.delegati = response.data;
                        this.loadDocForRichiestaCopie(this._idRicorso);
                    }
                });
        }


    }

    disableOpenCopy() {
        this.linkRichiestaCopie = false;
    }
    generaCopie() {
        console.log(this.copie);
        // this.print('copy','',this.dataRicorso.ricorrenti,null);  // original
        this.printNota_Copie('copy', '', this.dataRicorso.ricorrenti, this._localBeneficiario);  // Luke 02/03/2021 : cognome beneficiario aggiunto ai documenti
        console.log('genera copie clciccato');
    }
    generaNotaRuolo(){
        console.log('genera nota ruolo lanciato da emit!');
        this.printNota_Copie('note', '', this.dataRicorso.ricorrenti, this._localBeneficiario);  // Luke 02/03/2021 : cognome beneficiario aggiunto ai documenti

    }

    disableOpenNotaRuolo() {
        this.linkNotaIscrizione = false;
    }

    scartaCopie() {
        console.log('id ricorso ', this._idRicorso);
        console.log('tipo ', this.copie.kind);
        this.dbService.discardCopy(this._idRicorso, this.copie.kind)
            // .map(res => res.json())
            .subscribe(data =>  alert((data as any).msg));
    }
    confermaCopie() {
        console.log('id ricorso ', this._idRicorso);
        console.log('tipo ', this.copie.kind);
        this.dbService.acceptCopy(this._idRicorso, this.copie.kind)
            // .map(res => res.json())
            .subscribe(data =>  alert((data as any).msg));
    }


    openDomicilioDigitale(){
        this.dbService.getRicorsoFromDb(this._idRicorso,this.getIdTribunale())
            .subscribe(data => this.configRicorrentiForDomicilioDigitale((data as Resp).data,this._idRicorso));

    }
    openIdo(){


    console.log("...ho aperto la modale idoneità");

       this.dbService.getRicorsoFromDb(this._idRicorso,this.getIdTribunale())
    .subscribe(data => this.configRicorrentiForIdo((data as Resp).data,this._idRicorso));

  }
    openedDomicilioDigitale(){
        this.dataDocumento=formatDate(new Date().toDateString(), 'dd/MM/yyyy', 'it-IT');
    }

  openedIdo(){
    this.dataDocumento=formatDate(new Date().toDateString(), 'dd/MM/yyyy', 'it-IT');
    console.log("openED ido()");
  }

  getQualifica(q:string){
          /*

           // queste opzioni provengono dal modulo di compilazione del ricorso, dallla componente ricorrente
            <option value="volontario">volontario/familiare non ricorrente candidato A.d.S.</option>

            <option value="candidato">ricorrente candidato A.d.S.</option>
            <option value="nonCandidato">ricorrente non si candida come amministratore</option> */
     if(q=="candidato")
       return "candidato A.d.S.";
     if(q=="volontario")
       return  "volontario/familiare non ric. candidato A.d.S.";


     return q;
  }

  setProposteNominativiForDomicilioDigiale(nominativi){
      this.domicilioDigitaleData.proposteNominativi = [];
      for(const nom of nominativi){
          this.domicilioDigitaleData.proposteNominativi.push(nom.propostaAds);
      }
  }

  setProposteNominativi(nominativi){
      this.ido.proposteNominativi = [];
        for(const nom of nominativi){
            this.ido.proposteNominativi.push(nom.propostaAds);
      }
    }

  configRicorrentiForDomicilioDigitale(ricorso,idRicorso){
      this.dbService.getPropostaNominativiFromDb(idRicorso)
          .subscribe(data =>  this.setProposteNominativiForDomicilioDigiale((data as Resp).data));

      this.domicilioDigitaleData.ricorrenti=ricorso.ricorrenti;
      for(let i=0;i<this.domicilioDigitaleData.ricorrenti.length;i++){

         /* if(this.domicilioDigitaleData.ricorrenti[i].candidato=='nonCandidato' || this.domicilioDigitaleData.ricorrenti[i].candidato=='autoricorso'){
              this.domicilioDigitaleData.ricorrenti.splice(i, 1);  // Luke comment: splice(i, n) -> remove n elements from index i
          }*/
      }
      this.domicilioDigitaleData.beneficiario=ricorso.beneficiario;
      this.dbService.getWindow(this.statusService.getIdSportello())
          //.map(res => res.json())
          .subscribe(data => this.configureSportelloForDomicilioDigitale((data as Resp).data));
  }

  configRicorrentiForIdo(ricorso,idRicorso){

         this.dbService.getPropostaNominativiFromDb(idRicorso)
             .subscribe(data =>  this.setProposteNominativi((data as Resp).data));

    this.ido.ricorrenti=ricorso.ricorrenti;
    for(let i=0;i<this.ido.ricorrenti.length;i++){
      // Luke 12/03/2021: i ricorrenti candidati come "autoricorso" non devono comparire nella lista
      if(this.ido.ricorrenti[i].candidato=='nonCandidato' || this.ido.ricorrenti[i].candidato=='autoricorso'){
        this.ido.ricorrenti.splice(i, 1);  // Luke comment: splice(i, n) -> remove n elements from index i
      }
    }
    this.ido.beneficiario=ricorso.beneficiario;
    this.dbService.getWindow(this.statusService.getIdSportello())
    //.map(res => res.json())
    .subscribe(data => this.configureSportello((data as Resp).data));
  }

  configRicorrentiForRaccomandate(ricorso,idRicorso){
    this.racc.ricorrenti=ricorso.ricorrenti;
    this.racc.residenzaAlt=ricorso.details.residenzaAlt;
    this.racc.beneficiario=ricorso.beneficiario;
    this.dbService.getWindow(this.statusService.getIdSportello())
    //.map(res => res.json())
    .subscribe(data => this.configureSportelloForRaccomandate((data as Resp).data));

  }

    configureSportelloForDomicilioDigitale(sportello){
        console.warn('configure sportello chiamata');
        this._sportello=sportello[0];
        this._sportello.idSportello=+this._sportello.idSportello;
        this.domicilioDigitaleOpenModal();
        console.log(this._sportello.idSportello);
    }

    domicilioDigitaleOpenModal(){
        this.domicilioDigitaleModal.open();
        this.DomicilioDigitaleForRicorrenti=true;
    }

  configureSportello(sportello){
    console.warn('configure sportello chiamata');
    this._sportello=sportello[0];
    this._sportello.idSportello=+this._sportello.idSportello;
    this.openIdoModal();
    console.log(this._sportello.idSportello);
  }


  openIdoModal(){
      this.idoModal.open();
      this.IdoForRicorrenti=true;

  }
  configureSportelloForRaccomandate(sportello){
    this._sportello=sportello[0];
    this._sportello.idSportello=+this._sportello.idSportello;
    this.raccomandateModal.open();
    console.log(this._sportello.idSportello);
  }

  openEtichette(){
      this.etichetteModal.open();
      this.print();

  }





  closedUploadDocument(){
    this.getUploadCompleted=false;
  }
  dismissedUploadDocument(){
    this.getUploadCompleted=false;
  }
  openedUploadDocument(){
    this.selectedFile=null;
    this._dettagli.nomeDocumento="";
    this.getUploadCompleted=false;
  }
  openUploadModal(){
      this.uploadDocumentModal.open();
  }


  onFileSelected(event){

    console.log(event.target.files);

    this.selectedFile=<File[]>event.target.files;

    const loc_name=this.selectedFile[0].name;
    const loc_ext=loc_name.split(".");
    this.real_ext=loc_ext[1];

    this._dettagli.nomeDocumento=loc_ext[0];

    if(this.real_ext.toUpperCase()=="PNG" || this.real_ext.toUpperCase()=="PDF" || this.real_ext.toUpperCase()=="JPG" || this.real_ext.toUpperCase()=="JPEG"){
      this.showErrorPattern=false;

      //good
    }else{
     this.showErrorPattern=true;
    }


  }
  sendFormUploadDocument() {

    if(this.uploadDocumentModalForm.valid && !this.showErrorPattern){

      const fd= new FormData();

      const xhr = new XMLHttpRequest();

     for (let i = 0; i < this.selectedFile.length; i++) {
        const file = this.selectedFile[i];

        fd.append('files[]', file);
    }



      /*fd.append('selectedFile',this.selectedFile,this.selectedFile.name);*/
      fd.append('idRicorso',this._idRicorso.toString());
      //tofinish
      fd.append('tipo',(this.real_ext.toUpperCase()=="PDF" ? 'application/pdf' : this.selectedFile[0].type));
      fd.append("nomeDocumento",this._dettagli.nomeDocumento);
      fd.append("size",this.selectedFile[0].size.toString());

      console.log(fd);
      console.log('this.selectedFile[0] is');
      console.log(this.selectedFile[0]);

      this.dbService.uploadFile(fd)
      //.map(res => res.json() )
      .subscribe(data => {
        console.log("risposta:");
        console.log(data);
        this.successUploadDocument("documento caricato con successo!");
        this.getUploadCompleted=true;
        setTimeout( () => {  this.uploadDocumentModalForm.reset(); }, 1200 );

        console.log("fine risposta");
      });
    }
  }

//*** fine upload document ***//


//*** inizio lista documenti caricati ***//

  closedShowDocument(){
      this.listUploadedDocs=null;
  }
  dismissedShowDocument(){
      this.listUploadedDocs=null;
  }
  openedShowDocument(){


  }
  openShowDocumentModal(){
      this.showDocumentModal.open();
      this.dbService.getFileForRicorso(this._idRicorso)
      //.map(res => res.json())
      .subscribe(data =>
       this.setListUploadedDocs(data)

        );
  }


//*** fine lista documenti caricati ***//






   closedSelectedDocument(){}
  dismissedSelectedDocument(){}
  openedSelectedDocument(){


  }

showLoading(){

  if(this.loadingListUploadedDocs!=undefined)
  for(let i=0;i<this.loadingListUploadedDocs.length;i++)
    if(this.loadingListUploadedDocs[i])
      this.showLoadingValue=true;





   return this.showLoadingValue;
}




  openSelectedDocumentModal(idRicorso:number,idCaricamento:number,idx:number){
    this.showLoadingValue=false;
    console.log("documento con indice "+idx);
    this.pages=Array(1);
    this.loadingListUploadedDocs[idx]=true;
    console.log(` this.loadingListUploadedDocs[idx] vale ${this.loadingListUploadedDocs[idx]}`);
   /* for(let i=0;i<this.loadingListUploadedDocs.length;i++){
      if(i!=idx)
         this.loadingListUploadedDocs[idx]=false;
    }*/

     this.dbService.getUploadedDocumentFromId(idRicorso,idCaricamento)
      // .map(res=>res.json())
       .subscribe(data=>this.setContent(data,idx));


  }

  transform(html) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    }


setPage(i){
  this.url=this.transform("data:"+this.type+";base64, "+this.media[i]);
}

    setContent(data,idx){
      console.log(data);
      this.type=data.tipo;
      this.pages=[...Array(data.mediaSize).keys()];
      this.media=data.media;
      //if(data.mediaSize==1)
        this.content=data.media[0];

      const tmp=this.type.split("/");
      const ext=tmp[1];
      this.extension=ext;
      console.log(ext);
      this.path=this.transform(data.nomeDocumento+"."+ext);

    //  if(ext!="pdf")

     // this.url=this.transform(this.content);


      this.url=this.transform("data:"+data.tipo+";base64, "+this.content);
      console.log('url is');
      console.log(this.url);
   /* else
       this.url=this.transform("data:application/octet-stream;charset=utf-16le;base64, "+data.media);*/
      console.log("documento caricato con successo!");
      this.selectedDocumentModal.open();
      this.loadingListUploadedDocs[idx]=false;
      this.showLoadingValue=false;

    }

//** fine modla per il documento selezionato **//


//** proposta modale **//
openPropostaIncarico(){
    console.log('openPropostaIncarico');
    this.tipoCancellazione=DeleteRequestType.PROPOSTA_NOMINATIVO;

    this.propostaModal.open();
    this.idProposta = -1;
    this.dbService.getRicorsoFromDb(this._idRicorso,this.getIdTribunale())
    .subscribe(data => this.configBenefProposta((data as Resp).data,this._idRicorso));

    this.dbService.getPropostaNominativiFromDb(this._idRicorso)
        .subscribe(data => this.configListaPropostaNominativi((data as Resp).data));
  }

    configListaPropostaNominativi(listaPropostaNominativi){
       console.log('configListaPropostaNominativi la lista vale');
       console.log(listaPropostaNominativi);
       this.listaPropostaNominativi=listaPropostaNominativi;
    }

    setAnagraficaPropostaNominativo(proposta) {
        console.log('setAnagraficaPropostaNominativo click');
        if (proposta.idProposta != this.idProposta) {
            this.idProposta = proposta.idProposta;
            this._anagrafica = proposta.propostaAds;
        } else {
           this.idProposta = -1;
           this._anagrafica = new Anagrafica();
       }

       console.log('ora _anagrafica vale ');
       console.log(this._anagrafica);
    }

    cancellaAnagraficaPropostaNominativo(){
       this.dbService.cancellaAnagraficaPropostaNominativo(this.idToDelete).pipe(switchMap(data => {
           this.idProposta = -1;
           this.propostaModalForm.reset();
           this.nominativo.form.reset();
           return this.dbService.getPropostaNominativiFromDb(this._idRicorso);
       }))
            .subscribe(data => this.configListaPropostaNominativi((data as Resp).data));
    }


  configBenefProposta(ricorso,idRicorso){

    this._beneficiario=ricorso.beneficiario;
    console.warn(`#WARN l'id sportello vale ${this.statusService.getIdSportello()}`);
    this.dbService.getWindow(this.statusService.getIdSportello())
    //.map(res => res.json())
    .subscribe(data => this.configureSportelloForProposta((data as Resp).data));

}


  configureSportelloForProposta(sportello){
    console.warn('#WARN lo sportello per la proposta vale: ');
    console.log(sportello);
    this._sportello=sportello[0];
    this._sportello.idSportello=+this._sportello.idSportello;
    console.warn(`idSportello correttamenten condificato è ${this._sportello.idSportello}`);
    console.warn('oggetto sportello correttamnete copiato:');
    console.log(this._sportello);
    console.log("ho recuperato tutto");
  }



 closedPropostaModal(){
     this.closingPropostaModalOperations();
 }

 closingPropostaModalOperations(){
     this.linkProposta=false;
     this.pathProposta=null;
     this.availableProposta=false;
 }

  dismissedPropostaModal(){
    this.closingPropostaModalOperations();
    this.deletePdf(this.pathProposta).subscribe(()=>{
          this.propostaModal.dismiss();
          this.availableProposta=true;
    });
  }
  openedPropostaModal(){
    this.dataDocumento=formatDate(new Date().toDateString(), 'dd/MM/yyyy', 'it-IT');
    this.linkProposta=false;
    this.pathProposta=null;

  }





getPropostaCompleted(){
  return this.linkProposta;
}


sendFormProposta(){
  console.log("invio form");

  console.log(this._anagrafica);
    const dataPropostaNominativo = JSON.stringify({
        anagrafica:JSON.stringify(this._anagrafica),
        idRicorso:+this._idRicorso,
        idProposta:this.idProposta
    });

     const data = {
               anagrafica:JSON.stringify(this._anagrafica),
               beneficiario:JSON.stringify(this._beneficiario),
               sportello:JSON.stringify(this._sportello),
               nrg:this._ricorso.nrg,
                id:this.statusService.getSpecialId(),
                data:JSON.stringify(this.dataDocumento)
               };

    const json=JSON.stringify(data);

        console.log(json);

        const headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/proposta.php`,json,{headers:headers})
         .subscribe(_data => this.showDocumentProposta(_data));

        if(this.idProposta == -1)
            this.dbService.save2dbPropostaNominativo(dataPropostaNominativo).subscribe((res)=>this.setIdProposta(res));
        else if(this.idProposta >= 0){
            this.dbService.update2dbPropostaNominativo(dataPropostaNominativo).subscribe((res)=>this.setIdProposta(res));
        }


}

    setIdProposta(res:any){
       this.idProposta=res.id;
       this.dbService.getPropostaNominativiFromDb(this._idRicorso)
            .subscribe(data => this.configListaPropostaNominativi((data as Resp).data));
    }


//** fine propsota modale **//

  printIdo(){
    console.log("print ido chiamata");

    let data = {};

    if(this.IdoForRicorrenti) {
        data = {
            ricorrenti: JSON.stringify(this.ido.ricorrenti),
            beneficiario: JSON.stringify(this.ido.beneficiario),
            idx: this.ido.idx,
            luogo: this._sportello.comune,
            id: this.statusService.getSpecialId(),
            data:JSON.stringify(this.dataDocumento),
            nrg:JSON.stringify(this._ricorso.nrg,),
            intestazione:JSON.stringify(this.ido.intestazione)
        };
    }
    else {
        data = {
            ricorrenti:JSON.stringify(this.ido.proposteNominativi),
            beneficiario:JSON.stringify(this.ido.beneficiario),
            idx:this.ido.idxProposta,
            luogo:this._sportello.comune,
            id:this.statusService.getSpecialId(),
            data:JSON.stringify(this.dataDocumento),
            nrg:JSON.stringify(this._ricorso.nrg,),
            intestazione:JSON.stringify(this.ido.intestazione)
        };

    }

    const json=JSON.stringify(data);



        console.log(json);



        const headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
       return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/idoneita.php`,json,{headers:headers})
         //.map(res => res.json())
         .subscribe(_data => this.showDocumentIdo(_data));


  }

  sendFormRaccomandate(){

      this.dbService.getParentiFromDb(+this._idRicorso)
      //.map(res => res.json())
      .subscribe(data => this.setElencoParentiForRaccomandate((data as Resp).data));

  }

    sendFormEsportaAnagraficheModal(selection:DataEsportaAnagrafiche){
       this.dbService.esportaAnagraficheCSV(+this._idRicorso,selection)
           .subscribe(data => this.createAnagraficaCSV((data as Resp).data) );
    }

    createAnagraficaCSV(data){
        console.log('createAnagraficaCSV data is');
        console.log(data);
        this.refCsvObject = {};
        let csv='';


        if(data.ricorrenti != undefined){
            for(let i = 0; i < data.nRicorrenti; i++){
                if(this.refCsvObject == undefined)
                    this.refCsvObject[data.ricorrenti[i].codiceFiscale]= new Anagrafica();
                else {

                    if(this.refCsvObject[data.ricorrenti[i].codiceFiscale] == undefined)
                        this.refCsvObject[data.ricorrenti[i].codiceFiscale]= new Ricorrente();
                }
                this.refCsvObject[data.ricorrenti[i].codiceFiscale]=data.ricorrenti[i];
            }
        }
        if(data.beneficiario != undefined){
            if(this.refCsvObject[data.beneficiario.codiceFiscale] == undefined)
                this.refCsvObject[data.beneficiario.codiceFiscale]= new Beneficiario();
            this.refCsvObject[data.beneficiario.codiceFiscale]=data.beneficiario;
        }
        if(data.parenti != undefined){
            for(let i = 0; i < data.nParenti; i++){
                if(  this.refCsvObject[data.parenti[i].codiceFiscale] == undefined)
                    this.refCsvObject[data.parenti[i].codiceFiscale]= new Ricorrente();
                this.refCsvObject[data.parenti[i].codiceFiscale]=data.parenti[i];
            }
        }

        // intestazione
        csv+='NOME;COGNOME;SESSO;DATA_DI_NASCITA;LUOGO_DI_NASCITA;CF;INDIRIZZO;NAZIONE\n';

        for (const [cf, anagrafica] of Object.entries(this.refCsvObject)) {
            csv+=(anagrafica.nome!=undefined? anagrafica.nome:'')+';';
            csv+=(anagrafica.cognome!=undefined? anagrafica.cognome:'')+';';
            csv+=(anagrafica.sesso!=undefined? anagrafica.sesso:'')+';';
            csv+=(anagrafica.dataDiNascita!=undefined? anagrafica.dataDiNascita:'')+';';
            csv+=(anagrafica.luogoDiNascita!=undefined? anagrafica.luogoDiNascita:'')+';';
            csv+=(anagrafica.codiceFiscale!=undefined? anagrafica.codiceFiscale:'')+';';
            csv+=(anagrafica.indirizzo!=undefined? anagrafica.indirizzo.trim():'')+';';
            if(anagrafica.indirizzoStrutturato!= undefined){
                if(anagrafica.indirizzoStrutturato.indirizzo.nazionalita!=''){
                    csv+=anagrafica.indirizzoStrutturato.indirizzo.nazionalita;
                } else {
                    csv+=' ';
                }
            } else {
                csv += ' ';
            }
            csv+='\n';
        }
        this.downloadFile(csv);
    }




    downloadFile(data) {
        const anchorElement = document.createElement('a');
        const blob = new Blob([data], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        anchorElement.href = url;
        anchorElement.download = 'anagrafiche_ricorso_'+this._idRicorso+'.csv';
        anchorElement.click();

        window.URL.revokeObjectURL(url);
        //window.open(url);
    }

  sendFormIdo(){
    this.printIdo();
  }

    sendFormDomicilioDigitale(){
       this.refDomicilioDigitaleComponent.submitForm();
       this.printDomicilioDigitale();
    }

    printDomicilioDigitale(){
        let data = {};
        if(this.DomicilioDigitaleForRicorrenti) {
            data = {
                ricorrenti: JSON.stringify(this.domicilioDigitaleData.ricorrenti),
                beneficiario: JSON.stringify(this.domicilioDigitaleData.beneficiario),
                idx: JSON.stringify(this.domicilioDigitaleData.idx),
                sportello: JSON.stringify(this._sportello),
                id: JSON.stringify(this.statusService.getSpecialId()),
                domicilioDigitale:JSON.stringify(this.domicilioDigitale),
                data:JSON.stringify(this.dataDocumento)
            };
        }
        else {
            data = {
                ricorrenti:JSON.stringify(this.domicilioDigitaleData.proposteNominativi),
                beneficiario:JSON.stringify(this.domicilioDigitaleData.beneficiario),
                idx:JSON.stringify(this.domicilioDigitaleData.idxProposta),
                sportello:JSON.stringify(this._sportello),
                id:JSON.stringify(this.statusService.getSpecialId()),
                domicilioDigitale:JSON.stringify(this.domicilioDigitale),
                data:JSON.stringify(this.dataDocumento)
            };

        }
        const json=JSON.stringify(data);
        console.log(json);

        const headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/delegaTelematica.php`,json,{headers:headers})
            .subscribe(_data => this.showDocumentDomicilioDigitale(_data));

    }
    print(){
        console.log("print!");
        this.loadDoc(this._idRicorso);
    }

  getIdoCompleted(){
    return this.linkIdo;
  }
  getDomicilioDigitaleCompleted(){
       return this.linkDomicilioDigitale;
  }

  getRaccomandateCompleted(){
    return this.linkRaccomandate;
  }

  getEtichetteCompleted(){
    return this.linkEtichette;
  }

  disableDomicilioDigitaleForm(domicilioDigitaleForRicorrenti){
      this.DomicilioDigitaleForRicorrenti = domicilioDigitaleForRicorrenti;
      this.linkIdo = false;
  }

  disableIdoForm(IdoForRicorrenti){
       this.IdoForRicorrenti = IdoForRicorrenti;
       this.linkIdo = false;
  }

    disableIdoForm_Intestazione(){
        this.linkIdo = false;
        console.log('disabled ido link');
    }





loadDoc(idDocumento:number){
       console.log("loadDoc chiamata");
    this.dbService.getRicorsoFromDb(idDocumento,this.getIdTribunale())
    .subscribe(data => this.configLoadedDoc((data as Resp).data,idDocumento));

  }

configLoadedDoc(ricorso,idDocumento){

    console.log("config Loaded Doc chiamata");



      //this.statusService.setLoadedDoc(idDocumento,'RICORSO');

    console.log(ricorso);

      this.dbService.getParentiFromDb(+this._idRicorso)
      //.map(res => res.json())
      .subscribe(data => this.setElencoParenti((data as Resp).data,ricorso));
          /*this.statusService.updateRicorso(content);
          this.statusService.setViewRicorso(false);*/



  }
setElencoParenti(parenti,ricorso){
  console.log(parenti);



    //faccio chiamata a libreria per creare pdf

    const data = {
        residenzaAlt:ricorso.details.residenzaAlt,
        nRicorrenti:ricorso.details.nRicorrenti,
        nParenti:parenti.details.nRicorrenti,
        beneficiario:JSON.stringify(ricorso.beneficiario),
        ricorrenti:JSON.stringify(ricorso.ricorrenti),
        parenti:JSON.stringify(parenti.parenti),
        id:this.statusService.getSpecialId()};

    const json=JSON.stringify(data);



        console.log(json);



        const headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
       return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/etichette.php`,json,{headers:headers})
         //.map(res => res.json())
         .subscribe(_data => this.showDocument(_data));


}


setElencoParentiForRaccomandate(parenti){

  console.warn('in parnteza ho');
  console.log(parenti.parenti);



    //faccio chiamata a libreria per creare pdf



    const _newParenti:Ricorrente[]=[];



    if(parenti.parenti){

      console.warn('ciclo for set elenco parenti for raccomandate');
    for(const p of (parenti.parenti as Ricorrente[])){
      console.log(p);
      if((p  as Ricorrente).nome!=''){
          if(!this.racc.ricorrenti[this.racc.idx].nome.includes((p  as Ricorrente).nome) || !this.racc.ricorrenti[this.racc.idx].cognome.includes((p  as Ricorrente).cognome)){
            _newParenti.push((p  as Ricorrente));
          }
      }
    }


    }



    console.warn('dopo il filtarggio del ricorrente mittente ho');
    console.log(_newParenti);

      this.dbService.getWindow(this.statusService.getIdSportello()).subscribe(data => this.prepareRaccomandateToSend(data,_newParenti));






}

prepareRaccomandateToSend(sportello,parenti){

    console.warn('sportello vale');
    console.log(sportello.data[0]);
    const data = {
        sportello:JSON.stringify(sportello.data[0]),
        residenzaAlt:this.racc.residenzaAlt,
        ricorrenti:JSON.stringify(this.racc.ricorrenti),
        beneficiario:JSON.stringify(this.racc.beneficiario),
        idx:this.racc.idx,
        luogo:this._sportello.comune,
        parenti:JSON.stringify(parenti),
        id:this.statusService.getSpecialId()
    };

    const json=JSON.stringify(data);

        console.log(json);

       const headers=new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
       return this.http.post(`${AppSettings.API_ENDPOINT}/php/documents/${AppSettings.TRIBUNAL_ENDPOINT}/raccomandate.php`,json,{headers:headers})
         //.map(res => res.json())
         .subscribe(_data => this.showDocumentRaccomandate(_data));

}


//privacy


showPrivacy(data){
  console.log(data);
  this.pathPrivacy=data.fileName;
  this.linkPrivacy=true;
}


getPrivacyCompleted(){
  return this.linkPrivacy;
}




//end of privacy


showZip(data){
    console.log('showZip');
    console.log(data);
    if(data.status !='Failure') {
        this.pathZip = data.fileName;
        this.linkZip = true;
        this.linkZipStatus ='success';
    } else {
        this.linkZipStatus = 'failure';
        console.error('QUALCUNO METTE LINKZIP A FALSE!');
        this.linkZip = false;

    }
}

    isFailure(){
        return this.linkZipStatus =='failure';
    }

getZipCompleted(){
  return this.linkZip;
}


showDocumentRaccomandate(data){
  this.pathRaccomandate=data.fileName;
  this.linkRaccomandate=true;
  //this.dbService.addZip(+this._idRicorso,this.pathRaccomandate).subscribe(()=>{});

}

    showDocumentDomicilioDigitale(data){
       this.pathDomicilioDigitale=data.fileName;
       this.linkDomicilioDigitale=true;
       this.dbService.addZip(+this._idRicorso, this.pathDomicilioDigitale).subscribe(()=>{});

    }


    showDocumentIdo(data){
        this.pathIdo=data.fileName;
        this.linkIdo=true;
        this.dbService.addZip(+this._idRicorso, this.pathIdo).subscribe(()=>{});
    }

showDocumentProposta(data){
  this.pathProposta=data.fileName;
  this.linkProposta=true;
}

 showDocument(data){

    this.pathEtichette=data.fileName;
    this.linkEtichette=true;
    //this.preparePdf(this.pathEtichette.split('/').pop(),'boxEtichette');
    console.log("finito");


  }


 //a cosa serve questa funzione???? nota del 14 novembre 2019
  /*creaPermesso(){
    console.log("creo il permesso");
    this.dbService.creaPermesso(this.statusService.getSpecialId())
    //.map(res=>res.json())
      .subscribe(data => console.log(data));

  }*/

  sendFormNrg(){
    if(this.nrgModalForm.valid){
       const data = {
           idRicorso:this._idRicorso,
           nrg:this._ricorso.nrg
       };

    const json=JSON.stringify(data);



        console.log(json);







      console.log("nrgMODALFORM VALID");
      this.dbService.updateNrg(json)
      //.map(res => res.json())
      .subscribe(_data => this.successNrgForm((_data as any).msg));
    }

  }

  getNrgSuccess(){
    return this.showNrgSuccess;
  }

  successNrgForm(msg){
    console.log("nrg aggiornato con successo");
    this.showNrgSuccess=true;
console.log(this.showNrgSuccess);

    this.nrgSuccess=msg;

     setTimeout( () => {   this.showNrgSuccess=false;
console.log(this.showNrgSuccess);
     }, 4000 );

     //this._flashMessagesService.show(msg, { cssClass: 'alert-success', timeout: 2000 });

  }


  successUploadDocument(msg){
     this._flashMessagesService.show(msg, { cssClass: 'alert-success', timeout: 2000 });

  }

    preparePdf(fileName: string,id:string) {
        this.currentFileName = fileName;
        this.pdfViewer.getPdf(this.currentFileName,id);
    }

    cleanPdf(id:string){
      this.pdfViewer.cleanPdf(id);
    }

    openPdf(){
      this.pdfViewer.openPdf();
    }

    onLoadingStateChange(loading: boolean) {
        this.isLoading = loading;
    }

}
