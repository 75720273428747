import { Component, OnInit, ViewChild } from '@angular/core';
import {LangService} from '../../services/lang.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {StatusService} from '../../services/status.service';
import {DbService} from '../../services/db.service';
import { HttpClient } from '@angular/common/http';
import {FrontOffice} from '../../models/front-office';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BsModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import {Delegato} from '../../interfaces/delegato';
import {timer} from 'rxjs';


// Define interfaces for API responses
interface ApiResponse<T> {
    data: T;
    status?: number;
    message?: string;
}




@Component({
  selector: 'app-add-window',
  templateUrl: './add-window.component.html',
  styleUrls: ['./add-window.component.css'],
  providers: [DbService,LangService]
})
export class AddWindowComponent implements OnInit {
	public hideUpdateSuccessMessage:boolean;
  public hideUpdateLogoSuccessMessage:boolean;
  public getUploadCompleted:boolean;

   constructor(private route: ActivatedRoute,private router:Router,private _flashMessagesService: FlashMessagesService,private dbService:DbService, private http:HttpClient, private langService:LangService, private statusService:StatusService) {
    }


      private frontOffice=new FrontOffice();

      private windows:FrontOffice[];

  public saveSuccessMsg:string;
  public action:string;

  public statusMsg;
  public editAction:boolean;
  public idSportello= -1;
  public nomeFile:string;
  public selectedFile: File[];
  public showErrorPattern:boolean;


    // Add to existing properties
    public delegati: Delegato[] = [];
    public editingDelegato: Delegato | null = null;
    public editingDelegatoId = -1;
    public newDelegato: Partial<Delegato> = { nome: '', cognome: '' };



    private delegatoToDelete: {
        id: number;
        nome: string;
        cognome: string;
    } | null = null;


    /* metodi modale */

  @ViewChild('validationModal',{static:false})
    modal: BsModalComponent;

    @ViewChild('deleteModal', {static: false})
    deleteModal: BsModalComponent;


      @ViewChild('uploadLogoForm',{static:false}) uploadLogoForm;
      @ViewChild('delegatoForm', {static:false}) delegatoForm;

    selected: string;
    output: string;


    id:number;
    nome:string;
    backdropOptions = [true, false, 'static'];
    cssClass: string = '';

    animation: boolean = true;
    keyboard: boolean = true;
    backdrop: string | boolean = true;
    css: boolean = false;

    LabelCodiceFiscale(){
      return this.langService.codiceFiscale();
    }

   closed() {
        this.output = '(closed) ' + this.selected;
    }

    dismissed() {
        this.output = '(dismissed)';
    }

    opened() {
        this.output = '(opened)';
    }


    open() {
        this.modal.open();
    }

    setModal(idSportello:number,nomeSportello:string){
      this.id=idSportello;
      this.nome=nomeSportello;
      this.modal.open();
    }

    salvaModale(){
       this.delete(this.id);
       this.modal.close();
    }



    /* caricamento logo */






  onFileSelected(event){

    console.log(event.target.files);

    this.selectedFile=<File[]>event.target.files;

    let loc_name=this.selectedFile[0].name;
    let loc_ext=loc_name.split(".");
    let real_ext=loc_ext[1];

    if(real_ext.toUpperCase()=="JPG" || real_ext.toUpperCase()=="JPEG"){
      this.showErrorPattern=false;

      //good
    }else{
     this.showErrorPattern=true;
    }


  }


   uploadLogo() {

    if(this.uploadLogoForm.valid && !this.showErrorPattern){

      let fd= new FormData();

      let xhr = new XMLHttpRequest;

     for (let i = 0; i < this.selectedFile.length; i++) {
        let file = this.selectedFile[i];

        fd.append('files[]', file);
    }

     let loc_name=this.selectedFile[0].name;

      let loc_ext=loc_name.split(".");
    let real_ext=loc_ext[1];




      /*fd.append('selectedFile',this.selectedFile,this.selectedFile.name);*/
      fd.append('idSportello',this.idSportello.toString());
      fd.append('tipo',real_ext);

      fd.append("size",this.selectedFile[0].size.toString());

      console.log(fd);

      this.dbService.uploadLogo(fd)
      //.map(res => res.json() )
      .subscribe(data => {
        console.log("risposta:");
        console.log(data);
        this.successUploadLogo("documento caricato con successo!");
        this.getUploadCompleted=true;
        setTimeout( () => {  this.uploadLogoForm.reset(); }, 1200 );

        console.log("fine risposta");
      });
    }
  }

    successUploadLogo(msg){
     this._flashMessagesService.show(msg, { cssClass: 'alert-success', timeout: 2000 });

  }



    /*fine caricamento logo */

  /*fine metodi modale */

checkAccess(){
    if(!this.statusService.getAccess()){
        this.router.navigate(['']);
    }
  }

  ngOnInit() {
    this.checkAccess();
      if(this.route.snapshot.params['idSportello']!=undefined){
         this.idSportello=+this.route.snapshot.params['idSportello'];
         console.warn(`ho recuperato id sportello, ${this.idSportello}`);
     }


  	 this.windows=[];
  	 this.action="aggiungi sportello";
  	 this.hideUpdateSuccessMessage=true;
  	 this.updateWindowList();
     this.loadDelegati();
  	 console.log(this.windows);
  	 this.editAction=false;




  }

  updateMessage(){

    this._flashMessagesService.show(this.statusMsg, { cssClass: 'alert-success', timeout: 3700 });
    console.warn("updateMessage()");
  }


  editMode(){
    return this.idSportello!=-1;
  }


  updateWindowList(){
  	console.log("updateWindowList chiamata");
    if(!this.editMode()){
      console.log('editMode vale falso');
  	  this.dbService.getWindowList().subscribe(data => this.setList(data));
    }
    else{
        console.log('edit mode vale true');
        this.dbService.getWindow(this.idSportello).subscribe(data => this.setSingleWindow(data));
    }

  	 /*.map(res => {
           if(res.status==204)
             throw new Error("erroe");

           if(res.status==406)
              throw new Error("errore di rete");

           if(res.status==200)
             return res.json();



     })*/


  }

 setList(data){
 	console.log(data);
  	this.windows=data.data;
  	console.log("lista di sportelli:", this.windows);
  }
 setSingleWindow(data){
   console.log(data);
    this.windows=data.data;
    console.log("singolo sportello:", this.windows);
  }


   @ViewChild('windowForm',{static:false}) form;

  saveWindow(){
  	console.log("editAction vale: ",this.editAction);
		//this.form.controls.telefono.updateValueAndValidity();
      	if(this.form.valid){

      		if(this.editAction==false && !this.editMode()){
	   			this.dbService.addWindow(this.frontOffice)
		  		/*	.map(res => {
		           if(res.status==204)
		             throw new Error("erroe");

		           if(res.status==406)
		              throw new Error("errore di rete");

		           if(res.status==200)
		             return res.json();
				})*/
	  			.subscribe(data => this.showAddSuccessMsg(data));
  			}
  			if(this.editAction){
  				console.log("sono su ramo update");
  				this.dbService.updateWindow(this.frontOffice)
		  			/*.map(res => {
		           if(res.status==204)
		             throw new Error("erroe");

		           if(res.status==406)
		              throw new Error("errore di rete");

		           if(res.status==200)
		             return res.json();
				})*/
	  			.subscribe(data => this.showEditSuccessMsg(data));

  			}
       }
  }

showAddSuccessMsg(data){
	this.statusMsg="sportello aggiunto con successo";
	this.updateMessage();
	this.updateWindowList();
}

showEditSuccessMsg(data){
	this.statusMsg="sportello aggiornato con successo";
	this.updateMessage();
	this.editAction=false;
	this.action="aggiungi sportello";
	this.frontOffice=new FrontOffice();
	this.form.reset();
//	this.form.valid=true;
}







  edit(idSportello:number){

  		for(let w of this.windows){
  			if(w.idSportello==idSportello){
  				this.frontOffice=w;
  				this.statusMsg="ora puoi modficare lo sportello selezionato";
  				this.action="salva modifiche";
  				this.editAction=true;
  				this.updateMessage();
  				//flash
  				break;
  			}
  		}
  	}

  delete(idSportello:number){
  		this.dbService.deleteWindow(idSportello)
  		/*.map(res => {
	           if(res.status==204)
	             throw new Error("erroe");

	           if(res.status==406)
	              throw new Error("errore di rete");

	           if(res.status==200)
	             return res.json();
			})*/
  			.subscribe(data => this.showDeleteMsg(data));
}

  showDeleteMsg(data){
  	this.updateWindowList();

  	this.statusMsg="sportello cancellato con successo";
  	this.updateMessage();
  }
  	/*this.dbService.updateWindow(this.frontOffice)
	  			.map(res => {
	           if(res.status==204)
	             throw new Error("erroe");

	           if(res.status==406)
	              throw new Error("errore di rete");

	           if(res.status==200)
	             return res.json();
			})
  			.subscribe(data => this.showSuccessMsg(data));*/






  LabelNumero(){
    return this.langService.numero();
  }


backToMain(){
  return this.langService.backToMain();
}





    showSuccessMsg(data){


    	this.hideUpdateSuccessMessage=false;
    	this.saveSuccessMsg=this.langService.updateSuccessMsg();
    	this.updateWindowList();
      console.log(data);

    }




    processError(err){
      alert(err);
    }

    processUpdateAccount(acc){
      this.statusService.updateAccount(acc);
      console.log("update effettuato con successo");
    }


    /** delegati section **/

    // New methods for delegati management

    loadDelegati() {
        this.dbService.getDelegatiList(this.idSportello)
            .subscribe((response: ApiResponse<Delegato[]>) => {
                if (response && response.data) {
                    this.delegati = response.data;
                }
            });
    }

    isEditingDelegato(idDelegato: number): boolean {
        return this.editingDelegatoId === idDelegato;
    }

    editDelegato(idDelegato: number) {
        const delegato = this.delegati.find(d => d.idDelegato === idDelegato);
        if(delegato) {
            this.editingDelegato = {...delegato};
            this.editingDelegatoId = idDelegato;
        }
    }

    cancelDelegatoEdit() {
        this.editingDelegato = null;
        this.editingDelegatoId = -1;
    }

// Called when clicking update button during edit
    updateDelegato() {
        if (this.editingDelegato) {
            this.dbService.updateDelegato(this.editingDelegato)
                .subscribe((response: ApiResponse<Delegato>) => {
                    this._flashMessagesService.show(
                        'Delegato aggiornato con successo',
                        { cssClass: 'alert-success', timeout: 2000 }
                    );
                    this.loadDelegati();
                    this.cancelEdit();
                }, error => {
                    this._flashMessagesService.show(
                        'Errore durante l\'aggiornamento del delegato',
                        { cssClass: 'alert-danger', timeout: 2000 }
                    );
                });
        }
    }

    addDelegato() {
        if (this.newDelegato.nome && this.newDelegato.cognome) {
            const delegato = {
                ...this.newDelegato,
                idSportello: this.idSportello
            };

            this.dbService.createDelegato(delegato)
                .subscribe((response: ApiResponse<Delegato>) => {
                    this._flashMessagesService.show(
                        'Delegato aggiunto con successo',
                        { cssClass: 'alert-success', timeout: 2000 }
                    );
                    this.loadDelegati();
                    // Reset form
                    this.newDelegato = { nome: '', cognome: '' };
                    // Reset the form and mark controls as pristine and untouched
                    if (this.delegatoForm) {
                        this.delegatoForm.resetForm();

                        // Access form controls and reset their states
                        Object.keys(this.delegatoForm.controls).forEach(key => {
                            const control = this.delegatoForm.controls[key];
                            control.markAsPristine();
                            control.markAsUntouched();
                            control.updateValueAndValidity();
                        });
                    }
                }, error => {
                    this._flashMessagesService.show(
                        'Errore durante l\'aggiunta del delegato',
                        { cssClass: 'alert-danger', timeout: 2000 }
                    );
                });
        }
    }


// Called when clicking the delete icon to show confirmation modal
    confirmDeleteDelegato(idDelegato: number) {
        const delegato = this.delegati.find(d => d.idDelegato === idDelegato);
        if (delegato) {
            this.delegatoToDelete = {
                id: delegato.idDelegato,
                nome: delegato.nome,
                cognome: delegato.cognome
            };
            this.deleteModal.open();
        }
    }

// Called from the modal when confirming deletion
    confirmDelete() {
        if (this.delegatoToDelete) {
            this.dbService.deleteDelegato(this.delegatoToDelete.id)
                .subscribe((response: ApiResponse<any>) => {
                    this._flashMessagesService.show(
                        'Delegato eliminato con successo',
                        { cssClass: 'alert-success', timeout: 2000 }
                    );
                    timer(1200).subscribe(()=>{
                        this.loadDelegati();
                    });

                    this.deleteModal.close();
                    this.delegatoToDelete = null;
                }, error => {
                    this._flashMessagesService.show(
                        'Errore durante l\'eliminazione del delegato',
                        { cssClass: 'alert-danger', timeout: 2000 }
                    );
                });
        }
    }



// Called when clicking cancel during edit
    cancelEdit() {
        this.editingDelegato = null;
        this.editingDelegatoId = -1;
    }

    /* end of delegati section **/



}
