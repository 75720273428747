import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {Delegato} from '../../interfaces/delegato';
import {DbService} from '../../services/db.service';

@Component({
  selector: 'richiesta-copie',
  templateUrl: './richiesta-copie.component.html',
  styleUrls: ['./richiesta-copie.component.css']
})
export class RichiestaCopieComponent implements OnInit {



    @Input() copie;
    @Input() idTribunale=1;

    @Input() ricorrenti;
    @Output() sendCopie = new EventEmitter();
    @Output() disableSendCopie= new EventEmitter();

    @Input() delegati: Delegato[] = []; // Aggiungi questo Input

      constructor(private dbService: DbService) { }


    @ViewChild('richiestaCopieForm',{static:false}) form;


    selectedDelegatoId: number | string = '';
    showNewDelegatoForm = false;
    newDelegato = {
        nome: '',
        cognome: '',
        archiviaDelegato: false
    };



    disableForm(){
    console.warn("form modificato");
    this.disableSendCopie.emit();
  }

  valuechange(val){
     this.disableSendCopie.emit();
  }

  sendForm(){
    console.log('sendForm');
  	if(this.form.valid){
        this.handleNewDelegato();
  		this.sendCopie.emit();

  	}


  }

  loggami(event){
    setTimeout(()=>{
      console.log(`il bool vale ${this.copie.nominaUrgenteBool}`);
      console.log(`evento vale ${event}`);
    },400);
  }



  ngOnInit() {

  }

    loadDelegati() {
        console.log('richiesta copie copmponent loadDelegati()');
        this.dbService.getDelegatiList(this.copie.idSportello).subscribe(
            (response: any) => {
                if (response && response.data) {
                    this.delegati = response.data;
                    console.log('array delegati vale:');
                    console.log(this.delegati);
                } else {
                    this.delegati = [];
                }
            }
        );
    }

    onDelegatoChange(event: any) {
        const value = event.target.value;
        if (value === 'altro') {
            this.showNewDelegatoForm = true;
            this.copie.delegatoId = null;
        } else {
            this.showNewDelegatoForm = false;
            this.copie.delegatoId = parseInt(value, 10);
        }
        if (value === 'none'){
            this.newDelegato.nome = '';
            this.newDelegato.cognome = '';
        }
       // this.disableForm();
    }

    handleNewDelegato() {
        if (this.newDelegato.nome && this.newDelegato.cognome) {
            /* if (this.newDelegato.archiviaDelegato) {
                // Save to database if checkbox is checked
                const delegato = {
                    nome: this.newDelegato.nome,
                    cognome: this.newDelegato.cognome,
                    idSportello: this.copie.idSportello
                };

                this.dbService.createDelegato(delegato).subscribe(
                    (response: any) => {
                        if (response && response.data) {
                            this.delegati.push(response.data);
                            this.copie.delegatoId = response.data.idDelegato;
                        }
                    }
                );
            } */

            // Set the delegato info in copie object regardless of saving
            this.copie.delegatoNome = this.newDelegato.nome;
            this.copie.delegatoCognome = this.newDelegato.cognome;
        } else {
            this.copie.delegatoNome = '';
            this.copie.delegatoCognome = '';
        }
        //this.disableForm();
    }

}
